// export const environment = 'PRODUCTION';
// export const apiHostName = 'http://52.187.127.229:8080';
// export const host = 'http://52.187.127.229:8085'
// export const adminApiUrl = "http://52.187.127.229:8083";
// export const authority = "http://52.187.127.229:8082";
export const environment = 'DEVELOPMENT';
export const apiHostName = 'https://localhost:44355';
export const host = 'http://localhost:3000';
export const adminApiUrl = "https://localhost:44302"; //admin api
export const authority = "https://localhost:44310"; //sts
export const signaturePath = 'images/signatures';
export const gurantorPath = 'images/gurantors';
export const nomineePath = 'images/nominees';
export const photoPath = 'images/photos';
export const productPhotoPath = 'images/products';
export const shopPhotoPath = 'images/shops';
export const documentArchivePhotoPath = 'images/documentArchive';
export const documentPath = 'images/documents';
export const leaveAttachment = 'leave';
